import React, { useEffect, useState } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, mixed } from "yup"
import { useForm } from "react-hook-form";
import * as Yup from 'yup'
import { useDispatch, useSelector } from "react-redux";
import CustomInput from '../../common/CustomInput';
import moment from "moment"
import { editProductPrice, getuniltList } from '../../../redux/actions/productActions';
import SelectInput from '../../common/SelectInput';
const EditUnitModal = ({ openModal, onCloseModal, modaldata, product_id }) => {
    const dispatch = useDispatch()
    const { singleproductList, unitList } = useSelector(state => state.product)
    let format = "DD-MM-YYYY"

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)


    const schema = object().shape({

    })

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema),
        //   defaultValues: {
        //     status: data?.orderStatus
        // }

    })

    useEffect(() => {
        
        if (modaldata) {
            setStartDate(moment(modaldata?.fromDate, "YYYY-MM-DD"))
            setEndDate(moment(modaldata?.endDate, "YYYY-MM-DD"))
            setValue("name", modaldata?.name)
            setValue("Variant_Description", modaldata?.description)
            setValue("Selling_Price", modaldata?.sellingPrice)
            setValue("Regular_Price", modaldata?.costPrice)
            setValue("Offer_Price", modaldata?.offerPrice)
            setValue("startDate", moment(modaldata?.fromDate, "YYYY-MM-DD"))
            setValue("EndDate", moment(modaldata?.toDate, "YYYY-MM-DD"))
            // setValue("unit", modaldata?.unit)
        }
    }, [modaldata])


    const handlesubmitform = (data) => {
        console.log(data, "handle click....")
        const unitmodal = unitList.find((name) => name._id === data?.unit)


        let arr = {
            "_id": modaldata?._id,
            "product_id": modaldata?.product_id,
            "category": modaldata?.category,
            "subCategory": modaldata?.subCategory,
            "unit": modaldata?.unit,
            "units": modaldata?.units,
            "name": data?.name === "" ? modaldata?.name : data?.name,
            "description": data?.Variant_Description === "" ? modaldata?.description : data.Variant_Description,
            "sellingPrice": data.Selling_Price === "" ? modaldata?.Selling_Price : parseFloat(data?.Selling_Price),
            "costPrice": data.Regular_Price === "" ? modaldata?.costPrice : parseFloat(data?.Regular_Price),
            "offerPrice": data.Offer_Price === "" ? modaldata?.offerPrice : parseFloat(data?.Offer_Price),
            "fromDate": data?.startDate === "" ? modaldata?.fromDate : data?.startDate,
            "toDate": data?.EndDate === "" ? modaldata?.toDate : data?.EndDate,
        }


        const find = singleproductList?.variants.filter((id) => id?.unit === modaldata?.unit === ""?[]:modaldata?.unit)

        const filter = find.filter((id) => id._id !== modaldata?._id)
        filter.push(arr)

        console.log(filter, "filter console")

        const othersdata = singleproductList?.variants.find((id) => id.unit !== modaldata?.unit)
        const others = singleproductList?.variants.filter((id) => id.unit !== modaldata?.unit)

        let  unit = {
            id: data?.unit === "" || data?.unit === undefined ? modaldata?.units?._id : unitmodal?._id,
            name: data?.unit === "" || data?.unit === undefined ? modaldata?.units?.name : unitmodal?.name
        }
        //  let unit = { id: data?.unit === "" || data?.unit === undefined ? modaldata?.units?._id : unitmodal?._id,
        //  name: data?.unit === "" || data?.unit === undefined ? modaldata?.units?.name : unitmodal?.name }

        let variants = []
        let otherunit = { id: othersdata?.units?._id, name: othersdata?.units?.name }
        let othervarients = []
        let main = []

        filter.map((res) => {
            variants.push({
                "name": res.name,
                "description": res.description,
                "costPrice": res.costPrice,
                "sellingPrice": res.sellingPrice,
                "offerPrice": res.offerPrice,
                "fromDate": res.fromDate === null ? null : moment(res.fromDate, "YYYY-MM-DD").format(),
                "toDate": res.toDate === null ? null : moment(res.toDate, "YYYY-MM-DD").format()
            })
        })

        unit.variants = variants;

        others.map((res) => {
            othervarients.push({
                "name": res.name,
                "description": res.description,
                "costPrice": res.costPrice,
                "sellingPrice": res.sellingPrice,
                "offerPrice": res.offerPrice,
                "fromDate": res.fromDates === null ? null : moment(res.fromDate, "YYYY-MM-DD").format(),
                "toDate": res.toDate === null ? null : moment(res.toDate, "YYYY-MM-DD").format()
            })
        })
        otherunit.variants = othervarients;

        main.push(unit,otherunit)

        console.log(unit, "unit")

        let val = {
            category_id: modaldata?.category,
            subcategory_id: modaldata?.subCategory,
            units: main,
            id: product_id,
            status: singleproductList?.status,
        }
     
        dispatch(editProductPrice(val))
        onCloseModal()
    }

    useEffect(() => {
        dispatch(getuniltList())
    }, [])



    return (
        <Modal isOpen={openModal} toggle={onCloseModal}>
            <ModalHeader toggle={onCloseModal}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    Edit Units
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <SelectInput fieldLabel='Unit List'

                        control={control}
                        error={errors.unit}
                        fieldName='unit'
                    >
                        <option >select Unit</option>
                        {unitList?.map((unit) => (

                            <option key={unit.name} value={unit._id} className='formText'>
                                {unit.name}
                            </option>
                        ))}
                    </SelectInput>
                    <Row xl={"2"}>
                        <div>
                            <CustomInput
                                fieldLabel={'Variant Name:'}
                                control={control}
                                error={errors.name}
                                fieldName={'name'}
                            />
                        </div>
                        <div>

                            <CustomInput
                                fieldLabel='Variant Description'
                                control={control}
                                error={errors.Variant_Description}
                                fieldName={'Variant_Description'}
                            />
                        </div>
                    </Row>

                    <div>

                        <CustomInput
                            fieldLabel='Selling Price'
                            control={control}
                            error={errors.Selling_Price}
                            fieldName={'Selling_Price'}
                            type="number"
                        />
                    </div>
                    <div>

                        <CustomInput
                            fieldLabel='Maximum Retail Price'
                            control={control}
                            error={errors.Regular_Price}
                            fieldName={'Regular_Price'}
                            type="number"
                        />
                    </div>


                    <div>
                        <CustomInput
                            fieldLabel='Offer Price'
                            control={control}
                            error={errors.Offer_Price}
                            fieldName={'Offer_Price'}
                            type="number"
                        />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>


                            <CustomInput
                                fieldLabel={'Start Date'}
                                control={control}
                                error={errors.startDate}
                                fieldName={'startDate'}
                                defaultValue={startDate}
                                type={'date'}
                            />

                            <CustomInput
                                fieldLabel={'End Date'}
                                control={control}
                                error={errors.EndDate}
                                defaultValue={endDate}
                                fieldName={'EndDate'}
                                type={'date'}
                            />
                        </div>

                    </div>

                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    onClick={handleSubmit(handlesubmitform)}
                >
                    Save
                </Button>

                <Button
                    type="button"
                    color="secondary"
                    onClick={onCloseModal}
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default EditUnitModal