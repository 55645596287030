import React, { useEffect } from "react";
import {
  Modal,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInput from "../.././common/CustomInput";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  CreateDeliveryCharge,
  CREATEPROFILE,
  EditDeliveryCharge,
  EDITSETTING,
  GetDeliveryCharge,
  GETPROFILE,
} from "../../../redux/actions/SettingActions";
import { RESET_ERROR } from "../../../redux/constants/userConstants";
import { useState } from "react";
import { object, string, mixed } from "yup";
import { Loader } from "react-feather";

const EditDeliveryChargeModal = ({
  openModal,
  onCloseModal,
  datalist,
  error,
  data,
}) => {
  const { loading, delivery_data_updated, error: dataError } = useSelector(
    (state) => state.settings
  );

  const dispatch = useDispatch();

  const schema = object()
    .shape({
      normal_km: yup
        .number()
        .positive("number can't start with a minus")
        .required("Normal KM is required")
        .typeError("Normal KM is required"),
      normal_charge: yup
        .number()
        .positive("number can't start with a minus")
        .required("Delivery charge is required")
        .typeError("Delivery charge is required"),
      extra_charge_per_km: yup
        .number()
        .positive("number can't start with a minus")
        .required("Extra charge per km is required")
        .typeError("Extra charge per km is required"),
      normal_weight: yup
        .number("Normal weight is required")
        .positive("number can't start with a minus")
        .required("Normal weight is required")
        .typeError("Normal weight is required"),
      extra_weight_charge: yup
        .number()
        .positive("Extra weight charge is required")
        .required("Extra weight charge is required")
        .typeError("Extra weight charge is required"),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (delivery_data_updated) {
      toast.success("Delivery data successfully added");
      // onCloseModal()
      dispatch({
        type: RESET_ERROR,
      });
    } else {
      toast.error(dataError);
      dispatch({
        type: RESET_ERROR,
      });
    }
  }, [delivery_data_updated]);

  const onsubmit = async (datas) => {
    await dispatch(EditDeliveryCharge(datas));
    onCloseModal();
  };

  useEffect(() => {
    if (data) {
      setValue("normal_km", data?.normal_km);
      setValue("normal_charge", data?.normal_charge);
      setValue("extra_charge_per_km", data?.extra_charge_per_km);
      setValue("normal_weight", data?.normal_weight);
      setValue("extra_weight_charge", data?.extra_weight_charge);
    }
  }, [data]);

  return (
    <Modal isOpen={openModal} toggle={onCloseModal}>
      <ModalHeader toggle={onCloseModal}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          {data?.normal_km ? "Edit" : "Create"} Delivery Charge
        </h5>
      </ModalHeader>
      <ModalBody>
        <Form>
          <CustomInput
            fieldLabel="Normal Weight"
            control={control}
            error={errors.normal_weight}
            fieldName="normal_weight"
            type={"number"}
          />

          <CustomInput
            fieldLabel="Normal KM"
            control={control}
            error={errors.normal_km}
            fieldName={"normal_km"}
            type={"number"}
          />

          <CustomInput
            fieldLabel="Extra rate (weight)"
            control={control}
            error={errors.extra_weight_charge}
            fieldName="extra_weight_charge"
            type={"number"}
          />

          <CustomInput
            fieldLabel="Extra rate (KM)"
            control={control}
            error={errors.extra_charge_per_km}
            fieldName="extra_charge_per_km"
            type={"number"}
          />

          <CustomInput
            fieldLabel="Delivery Charge"
            control={control}
            error={errors.normal_charge}
            fieldName="normal_charge"
            type={"number"}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="primary"
          onClick={handleSubmit(onsubmit)}
          disabled={loading}
        >
          {loading ? <Loader /> : "Save"}
        </Button>
        <Button type="button" color="secondary" onClick={onCloseModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditDeliveryChargeModal;
