import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomTextArea from '../common/CustomTextArea';
import ImageInput from '../common/ImageInput';
import CustomInput from '../common/CustomInput';
import ImagesInput from '../common/ImagesInput';
import axios from 'axios';
import customAxios from '../../customAxios';
import { toast } from 'react-toastify';


const AddTopic = ({ openModal, onCloseModal, id }) => {
    const [loading, setLoading] = useState(false)
    const [itemSwitch, setItemSwitch] = useState(false)

    const dispatch = useDispatch()
    const schema = yup.object({
        name: yup.string().max(40).required("Name field is Required"),
        // location_home: yup.boolean().required("Location Home is Required"),
    }).required();

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema)
    });

    



    useEffect(() => {
        if (id) {
            getTopicDetails(id)
        }
    }, [id])



    const getTopicDetails = async (id) => {

        try {
            setLoading(true)
            let topic = await customAxios.get(`admin/topic/${id}`);

            let datas = topic?.data?.data
            
            let data = {
                name: datas?.name,
                location_home: datas?.location_home || false,
                status: datas?.status
            }
            
            
            reset(data)
            setItemSwitch(datas?.location_home)
        } catch (error) {
            toast.error(error)
        }
        finally {
            setLoading(false)
        }


    }
    

    const onSubmit = async (data) => {

        let url = 'admin/create-topic';
        if (id) {
            url = `admin/edit-topic`;
            data['id'] = id;
        }

        setLoading(true)

        try {
            let datas = await customAxios.post(url, {
                ...data,
                location_home: itemSwitch
            })


            toast.success(datas.data.message)
            reset({
                name: "",
                location_home: false,
            })
        } catch (error) {
            toast.error(error?.message)
        }
        finally {
            setLoading(false)
            onCloseModal()
        }
    };



    const clearclose = () => {
        reset({
            name: "",
            location_home: false,
        })
        onCloseModal()

    }


    return (

        <Modal isOpen={openModal} toggle={clearclose}>
            <ModalHeader toggle={clearclose}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    Add Topic
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <CustomInput
                        fieldLabel='Name :'
                        control={control}
                        error={errors.name}
                        fieldName='name'
                    />
                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        <h5
                            style={{ marginTop: 10, fontSize: 12 }}
                        >
                            Location Home
                        </h5>
                        <Input
                            type="checkbox"
                            {...register('location_home')} // Use register for checkbox with name 'location_home'
                            checked={itemSwitch}
                            onChange={(e) => setItemSwitch(e.target.checked)}
                        />
                        {/* {errors.location_home && <span className="text-danger">{errors.location_home.message}</span>} */}
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                    disabled={loading}
                >
                    {loading ? "Loading..." : "Save"}
                </Button>
            </ModalFooter>
        </Modal>

    )
}

export default AddTopic