import React, { useState, useEffect, useRef } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomInput from '../common/CustomInput';
//import data from '../unit/unitdata';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, mixed } from "yup"
import * as yup from 'yup'
import SelectInput from '../common/SelectInput';
import { useDispatch, useSelector } from 'react-redux';
import ImagesInput from '../common/ImagesInput';
import EditStore from './EditStore';
import { toast } from 'react-toastify';
import { RESET_ERROR } from '../../redux/constants/userConstants';
import { CreateStore, UpdateStore, UpdateStoreManagement } from '../../redux/actions/storeActions';
import { getuniltList } from '../../redux/actions/productActions';
import Reactotron from "reactotron-react-js"
import { MAPS } from "../../config/constant";
import Maps from '../settings/delivery/map/Map';
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import MyMapComponent from './MyMapComponent';
import EditMap from './EditMap';

const AddStore = ({ openModal, onCloseModal, data, setActiveData, title }) => {

    const autocompleteRef = useRef();



    const dispatch = useDispatch()


    const [datastore, setdatastore] = useState(data)
    const [unitArray, setUnitArray] = useState(null)
    const [images, setImage] = useState(null)
    const [loading, setLoading] = useState(false)
    const { unitList, newstore, error } = useSelector(state => state.store)
    const [inputFocused, setInputFocused] = useState(false);
    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];



    const schema = yup.object({
        storename: yup.string().required("Store is required")
            .matches(/^[A-Za-z\s]+$/, "Store name must contain only alphabets")
            .max(40, "Store name must be at most 40 characters")
        ,
        storeemail: yup.string().email("Email is not valid").required('Email is required'),
        primary_contact: yup
        .string()
        .nullable()
        .notRequired() // Allows the field to be null or undefined
        .test("isMobile", "Contact number needs to be atleast 10 digits", (value) => {
            if (!value) return true; // Skips validation if the value is null, undefined, or an empty string
            const pmobileRegex = /^[+]?[0-9]{8,16}$/; // Adjust regex for your specific needs
            return pmobileRegex.test(value);
        })
        .test("lengthCheck", "Contact number needs to be exactly 10 digits", (value) => {
            if (!value) return true; // Skip length check if value is empty
            return value.length === 10; // Only check length if there is a value
        }),
    sales_mobile: yup
        .string()
        .nullable()
        .notRequired() // Allows the field to be null or undefined
        .test("isMobile", "Sales number needs to be atleast 10 digits", (value) => {
            if (!value) return true; // Skips validation if the value is null, undefined, or an empty string
            const smobileRegex = /^[+]?[0-9]{8,16}$/; // Adjust regex for your specific needs
            return smobileRegex.test(value);
        })
        .test("lengthCheck", "Sales number needs to be exactly 10 digits", (value) => {
            if (!value) return true; // Skip length check if value is empty
            return value.length === 10; // Only check length if there is a value
        }),

        address: yup.object().required('Address is required').typeError('Address is required'),
        delivery_location: yup.array().required('Delivery location is required').typeError('Delivery location is required'),
    })

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue, getValues, setError, clearErrors } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            storename: '',
            storeemail: '',
            primary_contact: '',
            sales_mobile: '',
            address: '',
            delivery_location: ''
        }
    });


    useEffect(() => {
        if (data) {
            reset({
                storename: data?.storename,
                storeemail: data?.storeemail,
                primary_contact: data?.primary_contact,
                sales_mobile: data?.sales_mobile,
                address: data?.address,
                delivery_location: data?.delivery_location
            })
        } else {
            reset({
                storename: '',
                storeemail: '',
                primary_contact: '',
                sales_mobile: '',
                address: '',
                delivery_location: ''
            })
        }
    }, [data])


    //console.log({errors})

    // const { isLoaded } = useLoadScript({
    //     googleMapsApiKey: MAPS,
    //     libraries,
    //     region: 'in'
    // });

    // if (!isLoaded) {
    //     return <div style={{ width: '100%', height: 300, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>Loading...</div>
    // }



    const createstore = (datas) => {

        const formData = {
            "storename": datas?.storename,
            "storeemail": datas?.storeemail,
            "address": datas?.address,
            "primary_contact": datas?.primary_contact,
            "sales_mobile": datas?.sales_mobile,
            "delivery_location": datas?.delivery_location
        }

        // console.log();

        dispatch(data ? UpdateStoreManagement({ store_id: data?._id, ...formData }) : CreateStore(formData))
    }

    useEffect(() => {
        if (error) {
            // Reactotron.log(error,"err")
            // toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [error])



    // useEffect(() => {
    //     if (data) {
    //         let units = data?.unit.map(un => {
    //             return {
    //                 value: un.id,
    //                 label: un.name
    //             }
    //         })

    //         console.log({ units })
    //         setValue("name", data?.name)
    //         setValue("description", data?.description)
    //         setValue("tax", data?.tax)
    //         setValue("unit", units ? units : [])
    //         setValue("localImage", data?.image ? true : false)
    //     }
    // }, [data])


    const clearclose = () => {
        onCloseModal()
        reset()

    }

    // useEffect(() => {
    //     dispatch(getuniltList())
    // }, [])



    const inputStyles = {
        height: 'calc(1.5em + .75rem + 2px)',
        padding: '.375rem .75rem',
        fontSize: '1rem',
        lineHeight: 1.5,
        color: '#495057',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        border: `1px solid ${inputFocused ? '#007bff' : '#ced4da'}`,
        borderRadius: '.25rem',
        transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
        width: '100%' // Ensure full width
    };

    const setDeliveryLocation = (value) => {
        setValue("delivery_location", value)
    }

    const handlePlaceChanged = () => {

        const place = autocompleteRef.current.getPlace();
        const formattedAddress = place?.formatted_address;
        const lat = place?.geometry?.location?.lat();
        const lng = place?.geometry?.location?.lng();

        if (formattedAddress && lat && lng) {
            setValue("address", {
                address: formattedAddress,
                latitude: lat,
                longitude: lng
            });
            clearErrors("address");
        }
    };




    return (
        <>
            <style>
                {`
                .pac-container {
                    z-index: 1050 !important;
                    margin-top: -80px;
                }
            `}
            </style>
            <Modal isOpen={openModal} toggle={clearclose} size="lg">
                <ModalHeader toggle={clearclose}>
                    <h5
                        className="modal-title f-w-600"
                        id="exampleModalLabel2"
                    >
                        {title}
                    </h5>
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col>
                                <CustomInput
                                    fieldLabel='Store Name'
                                    control={control}
                                    error={errors.storename}
                                    fieldName='storename'
                                // readOnly={data?.storename}
                                />
                            </Col>
                            <Col>
                                <CustomInput
                                    fieldLabel='Email Address'
                                    control={control}
                                    error={errors.storeemail}
                                    // readOnly={data?.storeemail}
                                    fieldName='storeemail'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CustomInput
                                    fieldLabel='Contact Number'
                                    control={control}
                                    error={errors.primary_contact}
                                    fieldName='primary_contact'
                                    //readOnly={data?.primary_contact}
                                    type={'number'}
                                />
                            </Col>
                            <Col>
                                <CustomInput
                                    fieldLabel='Contact Number (Sales)'
                                    control={control}
                                    error={errors.sales_mobile}
                                    // readOnly={data?.sales_mobile}
                                    fieldName='sales_mobile'
                                    type={'number'}
                                />
                            </Col>
                        </Row>
                        <Row xl="1">
                            <Col>
                                <div>{data?._id ? <EditMap setValue={setValue} control={control} editable={data?._id} polygons={getValues()?.delivery_location}/> :
                                    <MyMapComponent setValue={setValue} control={control} editable={data?._id} polygons={getValues()?.delivery_location} />}
                                    {/* <Autocomplete
                                        onLoad={onLoadAutocomplete}
                                        onPlaceChanged={handlePlaceChanged}
                                        options={{
                                            types: ['geocode'],
                                        }}
                                        onFocus={() => setInputFocused(true)}
                                        onBlur={() => setInputFocused(false)}
                                    >
                                        <input
                                            type="text"
                                            style={inputStyles}
                                            placeholder="Enter location"
                                        />
                                    </Autocomplete> */}
                                    {/* <Autocomplete
                                        apiKey={MAPS}
                                        onPlaceSelected={(place) => {
                                            // Handle place selection
                                            Reactotron.log(place, "place")
                                            const formattedAddress = place?.formatted_address;
                                            const lat = place?.geometry?.location?.lat();
                                            const lng = place?.geometry?.location?.lng();

                                            if (formattedAddress && lat && lng) {
                                                //getAddress(lat, lng);
                                                setValue("address", {
                                                    address: formattedAddress,
                                                    latitude: lat,
                                                    longitude: lng
                                                });
                                                clearErrors("address");
                                            }
                                        }}
                                        options={{
                                            types: ['geocode']
                                        }}
                                        onFocus={() => setInputFocused(true)}
                                        onBlur={() => setInputFocused(false)}
                                        style={{ border: 'none', width: "100%" }}
                                    /> */}
                                </div>
                            </Col>
                        </Row>
                        {/* <Row style={{ marginTop: 20 }}>
                            <Col>
                                <CardBody>
                                    <Label style={{ marginBottom: 6 }}>
                                        Delivery Location
                                    </Label>
                                    <Maps onPolygonComplete={setDeliveryLocation} />
                                    {(errors && errors?.coordinates) && <p style={{
                                        color: "red",
                                        display: "flex",
                                        flexDirection: "start",
                                        paddingLeft: "10px",
                                        fontSize: "12px",
                                    }}>{`${errors?.coordinates?.message}`}</p>}
                                </CardBody>
                            </Col>
                        </Row> */}


                        {/* <span style={{ color: "red" }}>Image resolution should be 768px * 1000px</span> */}


                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        disabled={loading ? true : false}
                        color="primary"
                        onClick={handleSubmit(createstore)}
                    >
                        {loading ? "Saving" : "Save"}
                    </Button>
                    {/* <Button
                    type="button"
                    color="secondary"
                    onClick={() =>{ 
                        onCloseModal()
                        reset()
                    }}
                >
                    Close
                </Button> */}
                </ModalFooter>
            </Modal>
        </>
    )
}

export default AddStore
