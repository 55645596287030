import axios from 'axios'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Breadcrumb, Button, Card, CardBody, CardHeader, Col, Container, Row, Spinner } from 'reactstrap'
import customAxios from '../../customAxios'
import AddTopic from './AddTopic'
import { useNavigate } from 'react-router-dom'
import ViewTopic from './ViewTopic'
import { toast } from 'react-toastify'

const Topics = () => {

    const [topics, setTopics] = useState([])
    const [open, setOpen] = useState(false)
    const [viewTopics, setViewTopics] = useState(false)
    const [id, setId] = useState(null)

    const navigate = useNavigate()


    useEffect(() => {
        getTopics()
    }, [])


    const closeModal = () => {
        setId(null)
        setOpen(false)
        getTopics()
    }


    const getTopics = async() => {
        try {
            const topics = await customAxios.get('admin/topics');

         setTopics(topics.data?.data)
        } catch (error) {
            
        }
        finally{
            setLoading(false)
        }
        
    }
    

    const [loading, setLoading] = useState(false)

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1)
    };


    const viewTopic = (id) => {
        setId(id)
        setViewTopics(true)
    }

    const handleEdit = (id) => {
        setId(id)
        setOpen(true)
    }

    const handledelete = async(id) => {
        setLoading(true)
        if (window.confirm("Are you sure you wish to delete this item?")) {
            try {
                await customAxios.delete(`admin/topic/${id}`)
                toast.success('Topic deleted successfully')

            } catch (error) {
                toast.error(error?.message)
                
            }
            getTopics()
        } else {
            getTopics()
        }
    }

    var columns = [
        {
            name: <b>{Capitalize("Date")}</b>,
            header: <b>{Capitalize("Date")}</b>,
            selector: row => `${moment(row?.created_at).format('DD-MM-YYYY')}`,
            style: {
                textAlign: "center",
            },

        },
        {
            name: <b>{Capitalize("name")}</b>,
            header: <b>{Capitalize("name")}</b>,
            selector: row => row?.name,
            style: {
                textAlign: "center",
            },

        },
        // {
        //     name: <b>{Capitalize("description")}</b>,
        //     header: <b>{Capitalize("Description")}</b>,
        //     selector: row => row?.description,
        //     style: {
        //         textAlign: "center",
        //     },
        // },
        {
            name: <b>{Capitalize("status")}</b>,
            header: <b>{Capitalize("status")}</b>,
            selector: row => row?.status,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("home")}</b>,
            header: <b>{Capitalize("home")}</b>,
            selector: row => row?.location_home ? 'Yes' : 'No',
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>Actions</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (

                <>
                    {/* <span 
                    onClick={() => viewTopic(row?._id)} 
                    >

                        <i
                            className="fa fa-eye"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "#e4566e",
                                cursor: "pointer"
                            }}

                        ></i>
                    </span> */}

                    <span 
                        onClick={() => handleEdit(row?._id)}
                    >
                        <i
                            className="fa fa-pencil"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "rgb(40, 167, 69)",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                    <span 
                        onClick={() => handledelete(row?._id)}
                    >
                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "red",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                </>
            )
        }
    ];


    const subHeaderComponentMemo = React.useMemo(() => {

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                <Button
                    type="button"
                    color="primary"
                    onClick={() => setOpen(true)}
                >
                    Create Topic
                </Button>
            </div>
        );
    }, []);


    return (
        <Fragment>
            <Breadcrumb title="Topics" parent="Order" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Topics</h5>
                            </CardHeader>
                            <CardBody>

                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    <Fragment>
                                        <DataTable
                                            pagination
                                            title=""
                                            columns={columns}
                                            progressPending={loading}
                                            
                                            
                                            //paginationTotalRows={orderList?.total}
                                            //onChangeRowsPerPage={handlePerRowsChange}
                                            progressComponent={<Spinner animation="grow" variant="info" />}
                                            //paginationComponentOptions={paginationComponentOptions}
                                            //onChangePage={handlePageChange}
                                            data={topics}
                                            fixedHeader
                                            subHeader
                                            subHeaderComponent={subHeaderComponentMemo}
                                        />

                                    </Fragment>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <AddTopic openModal={open} onCloseModal={closeModal} id={id} />
            {(viewTopics && id) && <ViewTopic openModal={viewTopics} onCloseModal={() => {
                setId(null)
                setViewTopics(false)
            }} id={id} />}
        </Fragment>
    )
}

export default Topics