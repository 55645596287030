import React, { Fragment, useEffect, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Col, Form, Input, Label, Row, Toast } from "reactstrap";
import SelectInput from "../common/SelectInput";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "../../assets/scss/coupon.scss";
import CustomInput from "../common/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { GetCategory, GETPRODUCTS } from "../../redux/actions/productActions";
import { GetStore } from "../../redux/actions/storeActions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CustomTextArea from "../common/CustomTextArea";
import { Loader } from "react-feather";
import { toast } from "react-toastify";
import {
  CouponCreate,
  CouponEdit,
  SingleCoupon,
} from "../../redux/actions/SettingActions";
import moment from "moment";
import { RESET_ERROR } from "../../redux/constants/userConstants";

const Tabset = () => {
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  // const [selectedProducts, setSelectedProducts] = useState([]);
  // const [selectedCategories, setSelectedCategories] = useState([]);
  // const [selectedStores, setSelectedStores] = useState([]);
  const [selectedType, setSelectedType] = useState("");

  const [searchParams] = useSearchParams();

  const edit = searchParams.get("edit");

  const { productlist, getcatogrylist } = useSelector((state) => state.product);
  const { getstorelist } = useSelector((state) => state.store);
  const {
    loading,
    coupon_create_message,
    coupon_error_message,
    single_coupon,
  } = useSelector((state) => state.settings);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleStartDate = (date) => {
    setstartDate(date);
  };

  useEffect(() => {
    if (coupon_create_message) {
      toast.success(coupon_create_message);
      dispatch({
        type: RESET_ERROR,
      });
      reset();
      navigate("/coupons/list-coupons");
    } else if (coupon_error_message) {
      toast.error(coupon_error_message);
    }
  }, [coupon_create_message, coupon_error_message]);

  const handleEndDate = (date) => {
    setendDate(date);
  };


  const schema = yup.object({
    coupon_code: yup.string().required("Coupon code is required"),
    discount_type: yup
      .object()
      .required("Discount type is required")
      .typeError("Discount type is required"),
    discount_value: yup
      .number()
      .typeError("Please enter a valid number")
      .positive("Please enter a positive number")
      .required("Discount type is required"),
    // start_date: yup.date().required("Start date is required"),
    // end_date: yup.date().required("End date is required"),
    usage_limit_type: yup
      .object()
      .required("Usage type is required")
      .typeError("Usage type is required"),
    usage_limit_value: yup
      .number()
      .typeError("Please enter a valid number")
      .positive("Please enter a positive number")
      .required("Usage value is required"),
    coupon_type: yup
      .object()
      .required("Coupon type is required")
      .typeError("Coupon type is required"),
    min_cart_value: yup
      .number()
      .typeError("Please enter a valid number")
      .positive("Please enter a positive number")
      .required("Minimum cart value type is required"),
    ...(selectedType && {
      [`coupon_` + selectedType]: yup
        .array()
        .required(
          selectedType?.charAt(0)?.toUpperCase() +
            selectedType?.slice(1) +
            " is required"
        )
        .typeError(
          selectedType?.charAt(0)?.toUpperCase() +
            selectedType?.slice(1) +
            " is required"
        ),
    }),
    coupon_details: yup.string().required("Coupon detail is required"),
    customer_eligibility: yup
      .object()
      .required("Eligibility is required")
      .typeError("Eligibility is required"),
  });

  useEffect(() => {
    if (single_coupon) {
      setstartDate(moment(single_coupon?.start_date).toDate());
      setendDate(moment(single_coupon?.end_date).toDate());
      setSelectedType(single_coupon?.coupon_type);

      reset({
        coupon_code: single_coupon?.coupon_code,
        coupon_details: single_coupon?.coupon_details,
        discount_type: {
          label:
            single_coupon?.discount_type === "flat" ? "Flat" : "Percentage",
          value: single_coupon?.discount_type,
        },
        discount_value: single_coupon?.discount_value,
        start_date: moment("2024-09-06"),
        end_date: moment("2024-09-06"),
        usage_limit_type: {
          label:
            single_coupon?.usage_limit_type === "overall"
              ? "Overall"
              : "Per customer",
          value: single_coupon?.usage_limit_type,
        },
        usage_limit_value: single_coupon?.usage_limit_value,
        min_cart_value: single_coupon?.min_cart_value,
        coupon_type: {
          label: single_coupon?.coupon_type,
          value: single_coupon?.coupon_type,
        },
        // ["coupon_" + single_coupon.coupon_type]:
        //   single_coupon["coupon_" + single_coupon.coupon_type],
        coupon_categories: null,
        customer_eligibility: {
          label:
            parseInt(single_coupon?.customer_eligibility) === 1
              ? "New customer"
              : "Returning customer",
          value: single_coupon?.customer_eligibility,
        },
      });
      setValue(`coupon_${single_coupon?.coupon_type}`, single_coupon['coupon_'+single_coupon?.coupon_type])
    }

  }, [single_coupon]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    reset,
    control,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (id || edit) {
      dispatch(SingleCoupon(id || edit));
    } else {
      setSelectedType("");
      reset({
        coupon_code: "",
        coupon_details: "",
        discount_type: null,
        discount_value: "",
        usage_limit_type: null,
        usage_limit_value: "",
        min_cart_value: "",
        ...(selectedType && {
          [`coupon_` + selectedType]: null,
        }),
        coupon_products: null,
        coupon_categories: null,
        customer_eligibility: null,
      });

      setstartDate(new Date());
      setendDate(new Date());
      setSelectedType("");
      dispatch(GETPRODUCTS());
      dispatch(GetCategory());
      dispatch(GetStore());
    }
  }, [id, edit]);

  const onSubmit = (data) => {
    const discount_type = data?.discount_type?.value;
    const coupon_type = data?.coupon_type?.value;
    const customer_eligibility = data?.customer_eligibility?.value;
    const usage_limit_type = data?.usage_limit_type?.value;

    if (edit) {
      dispatch(
        CouponEdit({
          id: edit,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          coupon_details: data?.coupon_details,
        })
      );
    } else {
      dispatch(
        CouponCreate({
          ...data,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          customer_eligibility: parseInt(data.customer_eligibility),
          discount_type,
          coupon_type,
          customer_eligibility,
          usage_limit_type,
        })
      );
    }
  };

  return (
    <Fragment>
      <Tabs>
        <TabPanel>
          <div className="tab-pane fade active show">
            <Form className="needs-validation" noValidate="">
              {/* <h4>General</h4> */}
              <Row>
                <Col sm="12">
                  <Row>
                    <Col md="4">
                      <div className="form-group row">
                        <div className="col-md-9">
                          <CustomInput
                            fieldName={"coupon_code"}
                            fieldLabel={"CODE :"}
                            type={"text"}
                            error={errors.coupon_code}
                            control={control}
                            maxLength={"6"}
                            disabled={id || edit}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md="4">
                      <div className="form-group row">
                        <div className="col-md-9">
                          <SelectInput
                            fieldLabel="Discount Type : "
                            control={control}
                            error={errors.discount_type}
                            fieldName="discount_type"
                            disabled={id || edit}
                            options={[
                              { label: "Flat", value: "flat" },
                              { label: "Percentage", value: "percentage" },
                            ]}
                          />
                        </div>
                      </div>
                      {/* <div className="form-group row">
                        <Label className="col-xl-3 col-md-4">Start Date</Label>
                        <div className="col-md-7">
                          <DatePicker
                            selected={startDate}
                            onChange={handleStartDate}
                          />
                        </div>
                      </div> */}
                    </Col>

                    <Col md="4">
                      <div className="form-group row">
                        <div className="col-md-7">
                          <CustomInput
                            fieldName={"discount_value"}
                            fieldLabel={"Discount Value :"}
                            type={"number"}
                            error={errors.discount_value}
                            control={control}
                            maxLength={"6"}
                            min={"0"}
                            disabled={id || edit}
                          />
                        </div>
                        <div className="valid-feedback">
                          Please Provide a Valid Coupon Code.
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4">
                      <div className="form-group row">
                        <div className="col-md-9">
                          <CustomInput
                            fieldName={"min_cart_value"}
                            fieldLabel={"Cart Value :"}
                            type={"number"}
                            error={errors.min_cart_value}
                            control={control}
                            maxLength={"6"}
                            min={"0"}
                            disabled={id || edit}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md="4">
                      <div className="form-group row">
                        <div className="col-md-7">
                          <Label className="col-xl-3 col-md-4">
                            Start Date :{" "}
                          </Label>
                          <div className="col-md-7">
                            <DatePicker
                              minDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                              className="form-control custom-datepicker"
                              wrapperClassName="date-picker-wrapper"
                              selected={startDate}
                              onChange={handleStartDate}
                              disabled={id}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md="4">
                      <div className="form-group row">
                        <div className="col-md-7">
                          <Label className="col-xl-3 col-md-4">
                            End Date :{" "}
                          </Label>
                          <div className="col-md-7">
                            <DatePicker
                              minDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                              className="form-control custom-datepicker"
                              wrapperClassName="date-picker-wrapper"
                              selected={endDate}
                              endDate={endDate}
                              disabled={id}
                              onChange={handleEndDate}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4">
                      <div className="form-group row">
                        <div className="col-md-9">
                          <SelectInput
                            fieldLabel="Usage Type : "
                            control={control}
                            error={errors.usage_limit_type}
                            fieldName="usage_limit_type"
                            disabled={id || edit}
                            options={[
                              { label: "Overall", value: "overall" },
                              { label: "Per Customer", value: "percustomer" },
                            ]}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md="4">
                      <div className="form-group row">
                        <div className="col-md-7">
                          <CustomInput
                            fieldName={"usage_limit_value"}
                            fieldLabel={"Usage Value :"}
                            type={"number"}
                            error={errors.usage_limit_value}
                            control={control}
                            maxLength={"6"}
                            disabled={id || edit}
                            min={"0"}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md="4">
                      <div className="form-group row">
                        <div className="col-md-9">
                          <SelectInput
                            fieldLabel="Eligibility : "
                            control={control}
                            error={errors.customer_eligibility}
                            fieldName="customer_eligibility"
                            disabled={id || edit}
                            options={[
                              { label: "New customer", value: "1" },
                              { label: "Returning customer", value: "2" },
                            ]}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4">
                      <div className="form-group row">
                        <div className="col-md-9">
                          <SelectInput
                            fieldLabel="Coupon Type : "
                            control={control}
                            error={errors.coupon_type}
                            fieldName="coupon_type"
                            disabled={id || edit}
                            onChangeValue={(item) => {
                              setError("coupon_type", {
                                type: "required",
                                message: null,
                              });
                              setValue("coupon_type", item);
                              setSelectedType(item?.value);
                              setValue(`coupon_` + selectedType, null);
                            }}
                            options={[
                              { label: "Products", value: "products" },
                              { label: "Stores", value: "stores" },
                              { label: "Categories", value: "categories" },
                            ]}
                          />
                        </div>
                      </div>
                    </Col>

                    {selectedType && (
                      <Col md="4">
                        <div className="form-group row">
                          <div className="col-md-9">
                            <SelectInput
                              fieldLabel={
                                selectedType?.charAt(0)?.toUpperCase() +
                                selectedType?.slice(1) +
                                " : "
                              }
                              disabled={id || edit}
                              control={control}
                              error={errors[`coupon_` + selectedType]}
                              fieldName={`coupon_` + selectedType}
                              onChangeValue={(item) => {
                                setError("coupon_products", {
                                  type: "required",
                                  message:
                                    item && item?.length > 0
                                      ? null
                                      : selectedType?.charAt(0)?.toUpperCase() +
                                        selectedType?.slice(1) +
                                        " is required",
                                });

                                setValue(
                                  `coupon_` + selectedType,
                                  item && item?.length > 0 ? item : null
                                );
                              }}
                              isMultiple
                              options={
                                !selectedType
                                  ? []
                                  : (selectedType === "products"
                                      ? productlist || []
                                      : selectedType === "categories"
                                      ? getcatogrylist || []
                                      : getstorelist || []
                                    )?.map(({ _id, name, ...item }) => ({
                                      label: name || item?.slug,
                                      value: _id,
                                    }))
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    )}

                    <Col md="4">
                      <div className="form-group row">
                        <div className="col-md-9">
                          <CustomTextArea
                            disabled={id}
                            control={control}
                            fieldLabel={"Coupon Details : "}
                            error={errors.coupon_details}
                            fieldName={"coupon_details"}
                            type="textarea"
                            // readOnly={true}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </TabPanel>
      </Tabs>
      <div className="pull-right">
        {!id && (
          <Button
            disabled={loading}
            type="button"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {loading ? <Loader /> : "Save"}
          </Button>
        )}
      </div>
    </Fragment>
  );
};

export default Tabset;
