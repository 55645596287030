import React, { useState } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { Controller } from "react-hook-form";
import moment from "moment";

const CustomInput = ({
  fieldName,
  control,
  fieldLabel,
  placeholder,
  error,
  type,
  max,
  defaultValue,
  disabled,
  minimum,
  readOnly,
  eye,
  min
}) => {
  const [hide, setHide] = useState(true);

  return (
    <>
      <FormGroup>
        <Label htmlFor="recipient-name" className="col-form-label">
          {fieldLabel}
        </Label>
        <Controller
          name={fieldName}
          control={control}
          render={({ field }) => (
            <div
              style={{
                display: "flex",
              }}
            >
              <input
                {...field}
                aria-invalid={error ? "true" : "false"}
                className="form-control"
                placeholder={placeholder}
                id="exampleInputEmail1"
                type={
                  type
                    ? type === "password"
                      ? hide
                        ? "password"
                        : "text"
                      : type
                    : "text"
                }
                maxLength={max}
                readOnly={readOnly}
                defaultValue={defaultValue}
                disabled={disabled}
                min={min ? min : moment().format("YYYY-MM-DD")}
              />

              {eye && (
                <i
                  className={!hide ? "fa fa-eye" : "fa fa-eye-slash"}
                  onClick={() => setHide(!hide)}
                  style={{
                    width: 35,
                    fontSize: 20,
                    padding: 11,
                    color: "#000",
                    cursor: "pointer",
                  }}
                ></i>
              )}
            </div>
          )}
        />
        {error && (
          <p
            role="alert"
            style={{
              color: "red",
              display: "flex",
              flexDirection: "start",
              paddingLeft: "10px",
              fontSize: "12px",
            }}
          >
            {error?.message}
          </p>
        )}
      </FormGroup>
    </>
  );
};

export default CustomInput;
