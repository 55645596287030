
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import CustomInput from "../common/CustomInput";
import customAxios from "../../customAxios";
import { toast } from "react-toastify";
import Reactotron from "reactotron-react-js"
import Spinner from "../common/Spinner";

function ResetPass({ userID }) {

    const [loading, setLoading] = useState(false)

    const history = useNavigate();

    const schema = yup.object({
        password: yup.string().min(6).required(),
        confirmpassword: yup.string()
            .min(6)
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required(),
    }).required();

    const { register, formState: { errors }, handleSubmit, control } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data) => {

        const datas = {
            user_id: userID,
            password: data?.password
        }

        try {
            setLoading(true)
            const res = await customAxios.post('customer/change-password', datas)
            if (res?.status === 200 || 201) {
                toast.success('Password Changed Successfully')
                history(`/auth/login`);
            }
        } catch (err) {
            toast.error(err.message)
        }
        finally {
            setLoading(false)
        }
    }



    return (
        <Fragment>
            <div style={{ display: "flex", flexDirection: "column", height: "400px" }} >
                <Form className="form-horizontal " >
                    <div style={{ paddingBottom: "15px" }}>
                        <h1 style={{ fontSize: "22px" }}>Reset Your Password</h1>
                    </div>
                    <CustomInput
                        fieldName={"password"}
                        placeholder="New Password"
                        error={errors.password}
                        control={control}
                        type={"password"}
                    />
                    <CustomInput
                        fieldName={"confirmpassword"}
                        placeholder="Confirm Password"
                        error={errors.confirmpassword}
                        control={control}
                        type={"password"}
                    />
                    <div className="form-button pull-right">
                        {loading ? <Spinner /> : (<Button
                            color="primary"

                            onClick={handleSubmit(onSubmit)}>
                            Submit</Button>)}
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default ResetPass