import { LOADING, LOGIN_FAILED, LOGIN_SUCCESS, RESET_ERROR } from "../constants/userConstants";


export const authReducer = (state = { error: null }, action) => {
    switch (action.type) {

        case LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case LOGIN_SUCCESS:
            return {
                ...state,
                userData: action.payload,
                loginSuccess: true
            }

        case LOGIN_FAILED:
            return {
                ...state,
                error: action.payload
            }

        case RESET_ERROR:
            return {
                ...state,
                loginSuccess: null,
                error: null
            }

        default:
            return state;

    }

}