import React from 'react';
import './Switch.css';  // Import CSS for switch styling

const Switch = ({ isOn, handleToggle, id }) => {
  return (
    <span className="switch">
      <input 
        checked={isOn} 
        onChange={handleToggle} 
        className="switch-checkbox" 
        id={id} // Use the unique id here
        type="checkbox"
      />
      <label 
        className="switch-label" 
        htmlFor={id} // Use the same unique id here
      >
        <span className="switch-button" />
      </label>
    </span>
  );
};

export default Switch;
