import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";


import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import SelectInput from '../../common/SelectInput';
import { CREATEFEATURED, CreateUnit, EDITFEATURED, EditUnit } from '../../../redux/actions/productActions';
import { useDispatch, useSelector } from "react-redux";
import { RESET_ERROR } from '../../../redux/constants/userConstants';
import CustomInput from '../../common/CustomInput';
import ImagesInput from '../../common/ImagesInput';
const AddFeature = ({ openModal, onCloseModal, data, setActiveData }) => {

    console.log({ data }, 'FEATURED')

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const imageEdit = {
        name: yup.string().max(40).required("Name field is required")
    }

    const Create = {
        name: yup.string().max(40).required("Name field is required"),
        image: yup.mixed().required('Image is required')
    }

    const schema = yup.object(data ? imageEdit : Create).required();

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: ''
        }
    });


    const onSubmit = (datas) => {

        setLoading(true)

        const formData = new FormData();
        formData.append('name', datas?.name);
        if (datas.image) {
            formData.append('image', datas?.image)
        }
        if (data) {
            formData.append('id', data?._id);
            formData.append('status', "active");

        }

        if (data) {
            dispatch(EDITFEATURED(formData)).finally(() => setLoading(false));
        } else {
            dispatch(CREATEFEATURED(formData)).finally(() => setLoading(false));
            reset()
        }
        // if (data) {
        //     let input = {
        //         id: data?._id,
        //         name: datas?.name,
        //         status: "active"
        //     }
        //     dispatch(EDITFEATURED(input))

        // } else {
        //     dispatch(CREATEFEATURED(datas))
        //     setValue("name", "")
        // }

    };

    useEffect(() => {
        if (data) {
            setValue("name", data?.name)
        }
    }, [data])

    const clearclose = () => {
        setValue("name", "")
        setActiveData(null)
        onCloseModal()
        reset()

    }

    return (

        <Modal isOpen={openModal} toggle={clearclose}>
            <ModalHeader toggle={clearclose}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    {data ? 'Edit Feature' : 'Add Feature'}
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <CustomInput
                        fieldLabel='Feature Name:'
                        control={control}
                        error={errors.name}
                        fieldName='name'
                    />
                    {data &&
                        <div >
                            <img src={data?.image} style={{ width: "80px", height: "80px" }}></img>
                        </div>}
                    <ImagesInput
                        fieldLabel='Image :'
                        control={control}
                        error={errors.image}
                        fieldName='image'
                        type='file'
                        onChangeValue={(file) => setValue("image", file)}
                    />
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    disabled={loading ? true : false}
                    onClick={handleSubmit(onSubmit)}
                >
                    {loading ? "Saving" : "Save"}
                </Button>
                {/* <Button
                    type="button"
                    color="secondary"
                    onClick={()=>(
                        onCloseModal(),
                        setActiveData(null),
                        setValue("name","")
                    )
                       
                    }
                > Close
                </Button> */}
            </ModalFooter>
        </Modal>

    )
}

export default AddFeature
