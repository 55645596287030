//export const API_URL = 'https://pal.diginestsolutions.in/public/api';
//export const API_URL = 'https://192.168.1.54/public/api/';

// 1- corporate, 2-ta, 3-customer, 4 -supplier

export const env = "newDemo"

const URLS = {
    live: "https://api.fast2fastpay.com/",
    dev: "http://18.220.19.226:3010/",
    newDemo: "https://digistoreadmin.dnsappdemo.com/"
}
const url = {
    newDemo: "https://digistoreapi.diginestsolutions.in/public/api/",
    dev: "https://mmdcart.diginestsolutions.in/public/api",
    live: "https://api.mmdcart.com/public/api/",
    demo: "https://digistoreapi.diginestsolutions.in/public/api/",
    resto: "https://digirestoapi.diginestsolutions.in/public/api/",
    fashion: "https://digifashionapi.diginestsolutions.in/public/api/",
    ayurdx: "https://api.ayurdx.com/public/api/",
    pearl: "https://ecom.pearlglobalsolutions.com/backend/api/"
}


export const MAPS = "AIzaSyD6A0YVaJPZ5AWBbEFUD5Rfe39X4V2fG7A";

const key = {
    live: "nGw3tbaew9KgjGiXf3JwVnNEDUS4pmsA",
    dev: "nGw3tbaew9KgjGiXf3JwVnNEDUS4pmsA",
    demo: "nGw3tbaew9KgjGiXf3JwVnNEDUS4pmsA",
    newDemo: "nGw3tbaew9KgjGiXf3JwVnNEDUS4pmsA"
}


//'https://apidev.myfutton.com' //Dev mode= http://apidev.myfutton.com //Live Mode='http://api.myfutton.com';
//Dev mode= http://apidev.myfutton.com/api/v1/student //Live Mode=http://api.myfutton.com/api/v1/student';

export const BASE_URL = URLS[env]



export const API_URL = `${url[env]}`
export const API_KEY = `${key[env]}`