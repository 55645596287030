import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomInput from '../../common/CustomInput';
import data from '../unit/unitdata';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, mixed } from "yup"
import * as yup from 'yup'
import SelectInput from '../../common/SelectInput';
import { useDispatch, useSelector } from 'react-redux';
import { CreateBrand, getuniltList, UpdateBrand } from '../../../redux/actions/productActions';
import ImagesInput from '../../common/ImagesInput';
//import EditBrand from './EditBrand';
import { toast } from 'react-toastify';
import { RESET_ERROR } from '../../../redux/constants/userConstants';
import Reactotron from "reactotron-react-js"

const AddBrands = ({ openModal, onCloseModal, data, setActiveData, title }) => {



    const dispatch = useDispatch()

    const [datastore, setdatastore] = useState(data)
    const [unitArray, setUnitArray] = useState(null)
    const [images, setImage] = useState(null)
    const [loading, setLoading] = useState(false)
    const { unitList, newbrand, error} = useSelector(state => state.product)
    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
    const schema = yup.object({
        logo: yup.mixed().when("localImage", {
            is: false,
            then: yup.mixed()
                .test("required", "Image resolution should be 256px * 256px", (file) => {
                    // return file && file.size <-- u can use this if you don't want to allow empty files to be uploaded;
                    if (file) return true;
                    return false;
                })
                .test("fileSize", "The file is too large", (file) => {
                    //if u want to allow only certain file sizes
                    return file && file.size <= 2000000;
                }),
        }),
        name: yup.string()
        .matches(/^[A-Za-z0-9\s]+$/, "Brand name must contain only alphabets and numbers")
        .max(40, "Brand name must be at most 40 characters")
        .required("Brand name is required"),
    })

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            logo: null,
            localImage: false,
            name: '',

        }
    });

    //console.log({errors})


    // useEffect(() => {
    //     if (unitList) {
    //         let newUnits = unitList.map(unit => {
    //             return {
    //                 value: unit._id,
    //                 label: unit.name
    //             }
    //         })

    //         setUnitArray(newUnits);
    //     }

    //     if (newbrand) {
    //         reset()
    //     }


    // }, [unitList, newbrand])


    const createbrand= (datas) => {

        Reactotron.log(datas,"daada")

        setLoading(true)

        // console.log({datas})
        // let units = unitList.find((data) => data.id === datas._id)

        // let unit = [{
        //     id: units._id,
        //     name: units.name
        // }]

        // let units = datas.unit.map(un => {
        //     return {
        //         id: un.value,
        //         name: un.label
        //     }
        // })


        if (data) {

            // let input = {
            //     name: datas?.name === "" ? data?.name : data?.name,
            //     id: data?._id,
            //     status: data?.status,
            //     description: datas?.discription === "" ? data?.discription : data?.discription,
            //     tax: datas?.tax === "" ? data?.tax : data?.tax,
            //     image:datas?.image
            // }
            const formData = new FormData();
            formData.append('name', datas.name);
            formData.append('id', data?._id);
            formData.append('status', data?.status);
            if (datas?.image) {
                formData.append('image', datas.logo);
            }
            dispatch(UpdateBrand(formData)).finally(() => setLoading(false));
            // setValue("name", "")
            // setValue("description", "")
            // setValue("tax", "")
            // setActiveData(null)
        }
        else {
            const formData = new FormData();
            formData.append('name', datas.name);
            formData.append('logo', datas.logo);
            dispatch(CreateBrand(formData)).finally(() => setLoading(false));
            reset()
        }
    }

    useEffect(() => {
		if (error) {
			dispatch({
				type: RESET_ERROR
			})
		}
	}, [error])



    useEffect(() => {
        if (data) {
            // let units = data?.unit.map(un => {
            //     return {
            //         value: un.id,
            //         label: un.name
            //     }
            // })

            // console.log({ units })
            setValue("name", data?.name)
            setValue("localImage", data?.logo ? true : false)
        }
    }, [data])


    const clearclose = () => {
        onCloseModal()
        reset()

    }

    useEffect(() => {
        dispatch(getuniltList())
    }, [])

    const onImgLoad = ({ target: img }) => {
        if (img.offsetWidth === 256 && img.offsetHeight === 256) {
            setValue("logo", images)
        }
        else {
            setImage(null)
            setValue("logo", null)
            setError("logo", {
                type: 'custom',
                message: "Image resolution should be 256px * 256px"
            })
        }
    }

    return (

        <Modal isOpen={openModal} toggle={clearclose} size="lg">
            <ModalHeader toggle={clearclose}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    {title}
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                  
                            <CustomInput
                                fieldLabel='Brand Name :'
                                control={control}
                                error={errors.name}
                                fieldName='name'
                            />
                       
                            {data === null ? "" : <img src={data?.logo} style={{ width: 50, height: 50 }}></img>}

                            <ImagesInput
                                fieldLabel='Logo : 256px * 256px'
                                control={control}
                                error={errors.logo}
                                fieldName='logo'
                                type='file'
                                onChangeValue={(file) => {
                                    setImage(file)
                                }}
                            />
                       


                    {/* <span style={{ color: "red" }}>Image resolution should be 768px * 1000px</span> */}


                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    disabled={loading ? true : false}
                    color="primary"
                    onClick={handleSubmit(createbrand)}
                >
                    {loading ? "Saving" : "Save"}
                </Button>
                {/* <Button
                    type="button"
                    color="secondary"
                    onClick={() =>{ 
                        onCloseModal()
                        reset()
                    }}
                >
                    Close
                </Button> */}
            </ModalFooter>
            {images && <div style={{ height: 1, opacity: 0 }} ><img onLoad={onImgLoad} src={URL.createObjectURL(images)} /></div>}
        </Modal>

    )
}

export default AddBrands
