
import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import DataTables from "../common/DataTables";
import customAxios from "../../customAxios";
import AddRider from "./NewRider";
import { toast } from "react-toastify";
import Switch from "../common/switch/Switch";
import { useDispatch, useSelector } from "react-redux";
import { GetUsermanangementList, UpdateUserManagementStatus } from "../../redux/actions/SettingActions";
import moment from "moment";
import { RESET_ERROR } from "../../redux/constants/userConstants";

const Usermanagement = () => {


    const [open, setOpen] = useState(false)
    const [data, setData] = useState([])
    
    const customId = "delUser";
    const dispatch = useDispatch()

    const { userManageList, usermanagementdeleted } = useSelector(state => state?.settings)

    useEffect(() => {
        dispatch(GetUsermanangementList())
    }, [])

    useEffect(() => { 
        if(usermanagementdeleted) {
            toast.success('Deleted Successfully')
            dispatch({
                type: RESET_ERROR
            })
        } 
    }, [usermanagementdeleted])


    const handleDelete = async(id) => {
        if (window.confirm("Are you sure you wish to delete this item?")) {
            try {
                let deleteUser = await customAxios.delete(`admin/delete-rider/${id}`)
                if(deleteUser?.data?.message === "User deleted"){
                    toast.success("User deleted successfully",{toastId: customId})
                    dispatch(GetUsermanangementList())
                }
                else{
                    toast.error(deleteUser?.data?.message)
                }
            } catch (error) {
                toast.error(error?.message)
            }
            
            
            //getRidersList()
        }
    }

    const handleEdit = (data) => {
        setData(data)
        setOpen(true)
    }
    

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    

    const handleToggleStatus = (storeId, currentStatus) => {
        const updatedData = {
          id: storeId,
          status: currentStatus === "active" ? "inactive" : "active"
        };
      
        dispatch(UpdateUserManagementStatus(updatedData))
          .then(() => {
            toast.success(`Status updated successfully`);
            dispatch(GetUsermanangementList())
          })
          .catch((error) => {
            toast.error(`Failed to update status: ${error.message}`);
          });
      };

    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },
        {
            name: <b>{Capitalize("Name")}</b>,
            header: <b>{Capitalize("Name")}</b>,
            selector: row => row?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Email")}</b>,
            header: <b>{Capitalize("Email")}</b>,
            selector: row => row?.email,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Role")}</b>,
            header: <b>{Capitalize("Role")}</b>,
            selector: row => row?.role,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Created At")}</b>,
            header: <b>{Capitalize("Created At")}</b>,
            cell: (row, index) => moment(row?.created_at).format('DD-MM-YYYY hh:mm A')
        },
        {
            name: <b>{Capitalize("Image")}</b>,
            header: <b>{Capitalize("Image")}</b>,
            cell: (row, index) => (
                
                <>
                    <span>
                        <img style={{ width: 50, height: 50 }} src={'https://digistoreapi.diginestsolutions.in/public/' + row?.image}></img>
                    </span>
                </>

            )
        },
        {
            name: <b>{Capitalize("Mobile")}</b>,
            header: <b>{Capitalize("Mobile")}</b>,
            selector: row => row?.mobile,
            style: {
                textAlign: "center",
            },
        },
   
        {
            name: <b>{Capitalize("Status")}</b>,
            header: <b>{Capitalize("Status")}</b>,
            cell: row => (
                <Switch
                    isOn={row.status === "active"}
                    handleToggle={() => handleToggleStatus(row._id, row.status)}
                    id={`switch-${row._id}`}
                />
            ),
            style: { textAlign: "center" }
        },
        {
            name: <b>Actions</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (

                <>
                    <span onClick={() => handleDelete(row._id)} >

                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "#e4566e",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                    <span onClick={() => handleEdit(row)}>
                        <i
                            className="fa fa-pencil"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "rgb(40, 167, 69)",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                </>
            )
        }
    ];


    const closeModal = () => {
        setOpen(false)
        dispatch(GetUsermanangementList())
        setData(null)
    }



  return (
    <Fragment>
            <Breadcrumb title="User management" parent="Users" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Store managers / riders</h5>
                            </CardHeader>
                            <CardBody>
                                <Button style={{ float: "right", marginBottom: 20 }} onClick={() => setOpen(true)}>Create</Button>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    {userManageList?.length > 0 && <DataTables
                                        columns={columns}
                                        row={userManageList}
                                    />}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
           <AddRider openModal={open} onCloseModal={closeModal} data={data} />
        </Fragment>
  )
}

export default Usermanagement