import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import {
  Modal,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  filterOrder,
  getOrderList,
  getOrderStatus,
} from "../../redux/actions/OrderActions";
import { useNavigate } from "react-router-dom";
import { RESET_ERROR } from "../../redux/constants/userConstants";
import DataTable from "react-data-table-component";
import SearchHeader from "../common/header_components/searchHeader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import moment from "moment";
import {
  CouponDelete,
  GetCouponList,
} from "../../redux/actions/SettingActions";

// getorderlist
const ListCoupons = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    get_coupon_list,
    orderupdated,
    error,
    coupon_create_message,
    coupon_error_message,
  } = useSelector((state) => state.settings);
  const { loading } = useSelector((state) => state.auth);

  const [datas, setDatas] = useState([]);

  const [open, setOpen] = useState(false);
  const [assign, setAssign] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [activeData, setActiveData] = useState(null);
  const [search, setSearch] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [store, setStore] = useState("");
  const [rider, setRider] = useState(null);

  useEffect(() => {
    dispatch(GetCouponList());
  }, []);


  useEffect(() => {
    if (coupon_create_message) {
      
      toast.success(coupon_create_message);
      dispatch(GetCouponList());
    } else if (coupon_error_message){
      toast.success(coupon_error_message);
    }

    dispatch({
      type: RESET_ERROR
    })
  }, [coupon_create_message, coupon_error_message]);

  useEffect(() => {
    if (get_coupon_list) {
      setDatas(get_coupon_list);
    }
  }, [get_coupon_list]);

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const Navigatedetailpage = (id) => {
    navigate(`/coupons/create-coupons/${id}`);
  };

  const NavigateEditpage = (edit) => {
    navigate(`/coupons/create-coupons?edit=` + edit);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you wish to delete this coupon?")) {
      dispatch(CouponDelete(id));
    }
  };

  useEffect(() => {
    if (orderId) {
      setAssign(true);
    }
  }, [rider, orderId]);

  useEffect(() => {
    if (filterText) {
      let datas = get_coupon_list?.filter(
        (coup) =>
          coup?.coupon_code
            ?.toLowerCase()
            .includes(filterText.toLowerCase()) ||
          coup?.usage_limit_type
            ?.toString() 
            .toLowerCase()
            .includes(filterText?.toLowerCase()) ||
          coup?.coupon_type
            ?.toLowerCase()
            ?.includes(filterText?.toLowerCase())
      );

      setDatas(datas);
    }
  }, [filterText]);

  const schema = yup.object({
    fromDate: yup.string().required("From date required"),
    toDate: yup.string().required("To date required"),
  });

  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
        setDatas(get_coupon_list);
      }
    };

    return (
      <div style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
        <SearchHeader
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </div>
    );
  }, [filterText]);

  var columns = [
    {
      name: <b>{Capitalize("S.No")}</b>,
      header: <b>{Capitalize("S.No")}</b>,
      cell: (row, index) => index + 1,
    },
    {
      name: <b>{Capitalize("Coupon Code")}</b>,
      header: <b>{Capitalize("Coupon Code")}</b>,
      selector: (row) => `${row?.coupon_code}`,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Start Date")}</b>,
      header: <b>{Capitalize("Start Date")}</b>,
      selector: (row) => `${moment(row?.start_date).format("DD-MM-YYYY")}`,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("End Date")}</b>,
      header: <b>{Capitalize("End Date")}</b>,
      selector: (row) => `${moment(row?.end_date).format("DD-MM-YYYY")}`,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Discount Value")}</b>,
      header: <b>{Capitalize("Discount Value")}</b>,
      selector: (row) => row?.discount_value,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Usage Type")}</b>,
      header: <b>{Capitalize("Usage Type")}</b>,
        selector: (row) => row?.usage_limit_type,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Coupon Type")}</b>,
      header: <b>{Capitalize("Coupon Type")}</b>,
      selector: (row) => `${row?.coupon_type}`,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>Actions</b>,
      id: "delete",
      accessor: (str) => "delete",
      style: {
        textAlign: "center",
      },
      cell: (row, index) => (
        <div>
          <div>
            <span onClick={() => Navigatedetailpage(row?._id)}>
              <i
                className="fa fa-eye"
                style={{
                  width: 35,
                  fontSize: 20,
                  padding: 11,
                  color: "#e4566e",
                  cursor: "pointer",
                }}
              ></i>
            </span>

            <span onClick={() => NavigateEditpage(row?._id)}>
              <i
                className="fa fa-pencil"
                style={{
                  width: 35,
                  fontSize: 20,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                  cursor: "pointer",
                }}
              ></i>
            </span>

            <span onClick={() => handleDelete(row?._id)}>
              <i
                className="fa fa-trash"
                style={{
                  width: 35,
                  fontSize: 20,
                  padding: 11,
                  color: "red",
                  cursor: "pointer",
                }}
              ></i>
            </span>
          </div>
        </div>
      ),
    },
  ];



  const handlePageChange = (page) => {
    dispatch(getOrderList(page));
  };

  const paginationComponentOptions = {
    noRowsPerPage: true,
  };

  useEffect(() => {
    getOrderStatus();
  }, []);

  return (
    <Fragment>
      <Breadcrumb title="Coupon list" parent="Order" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid="true">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Coupon Details</h5>
              </CardHeader>
              <CardBody>
                <div className="clearfix"></div>
                <div id="basicScenario" className="product-physical">
                  <Fragment>
                    <DataTable
                      pagination
                      title=""
                      columns={columns}
                      progressPending={loading}
                      progressComponent={
                        <Spinner animation="grow" variant="info" />
                      }
                      data={datas}
                      fixedHeader
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                    />
                  </Fragment>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ListCoupons;
